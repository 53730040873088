<template lang="">
  <div>
    <h6 class="heading-small text-muted mb-4">ข้อมูลกลุ่มสถานที่ตั้ง</h6>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <div class="mb-4">
            <label class="form-label">ชื่อกลุ่มสถานที่ตั้ง</label>
            <base-input name="name" :rules="{required: true}" placeholder="ชื่อ" v-model="item.name"></base-input>
          </div>
        </div>
        <div class="col-sm-12">
          <hr class="my-4">
        </div>
        <div class="col-12 padding_top_20">
          <b-button variant="primary" @click="addLoction()" :disabled="item.mappings.length==locations.length" style="width: 135px;"><i class="fa fa-plus"></i> เพิ่มสถานที่ตั้ง</b-button>
          <div class="table-responsive padding_top_20">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center" >
                  <th>สถานที่ตั้ง</th>
                  <th style="min-width: 100px; width: 1px;">ลบ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in item.mappings" :key="`mapping_${data.id}_${index}`">
                  <td>
                    <Select2 v-model="data.id" :options="locations" placeholder="เลือกสถานที่ตั้ง"/>
                  </td>
                  <td class="text-center">
                    <button type="button" class="btn btn-danger btn-sm" @click="removeLocation(index)">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
                <tr v-if="item.mappings.length == 0" class="text-center">
                  <td colspan="2">ไม่มีข้อมูล</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr class="my-4">
    </div>
  </div>
</template>
<script>
export default {
  name: 'location-view-form-location',
  data () {
    return {
      locations: []
    }
  },
  props: [
    'item'
  ],
  methods: {
    addLoction () {
      let selectId = "";
      for(const v of this.locations){
        let dup = false;
        for(const vv of this.item.mappings){
          if(v.id==vv.id){
            dup = true;
            break;
          }
        }

        if(!dup){
          selectId = v.id;
          break;
        }
      }
      this.item.mappings.push({
        id: selectId
      });
    },
    removeLocation (index) {
      if (index > -1) {
        this.item.mappings.splice(index, 1);
      }
    },
    // changeLocation(data, index){
    //   const selectId = data.id;
    //   let selected = false;
    //   let cnt = 0;
    //   for(const v of this.item.mappings){
    //     if(index!=cnt&&v.id==selectId){
    //       selected = true;
    //       break;
    //     }
    //     cnt++;
    //   }

    //   if(selected){
    //     this.AlertUtils.alert("warning", "ข้อมูลสถานที่ตั้งมีการระบุแล้ว");
    //     data.id = "";
    //   }
    // }
  },
  async mounted() {
    this.locations = await this.HttpServices.getMasterData(`/master/getLocations`);
  }
}
</script>
